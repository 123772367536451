import { useState, useEffect } from 'react'

export function useIsMobile(width = 450) {
  const [isMobile, setIsMobile] = useState(null)

  useEffect(() => {
    const checkIfMobile = () => setIsMobile(window.innerWidth < width)
    checkIfMobile()
    window.addEventListener('resize', checkIfMobile)
    return () => window.removeEventListener('resize', checkIfMobile)
  }, [width])

  return isMobile
}
