import { ReactElement, useState } from 'react'
import Image from 'next/image'
import { urlForImage } from '../../lib/sanity.image'
import {
  GLOBAL_FALLBACK_IMAGE,
  SIGHT2SIGHT_FALLBACK_ALT,
  SIGHT2SIGHT_FALLBACK_TITLE,
} from '../../lib/constants'

type Props = {
  image: Image
  alt?: string
  title?: string
  priority?: boolean
  width?: number
  height?: number
  autoheight?: boolean
}

export function IdealImage({
  image: outerImage,
  alt = SIGHT2SIGHT_FALLBACK_ALT,
  title = SIGHT2SIGHT_FALLBACK_TITLE,
  width,
  height,
  autoheight,
  ...props
}: Props): ReactElement {
  const { image } = outerImage || GLOBAL_FALLBACK_IMAGE
  const [aspectRatio, setAspectRatio] = useState<number | null>(null)

  const hotspot = image?.hotspot || { x: 0.5, y: 0.5 }
  const objectPosition = `${hotspot.x * 100}% ${hotspot.y * 100}%`

  const src = autoheight
    ? urlForImage(image).dpr(2).url()
    : urlForImage(image)
        .width(width || 1080)
        .height(height || 720)
        .dpr(2)
        .url()

  const handleLoadingComplete = (img: HTMLImageElement) => {
    const { naturalWidth, naturalHeight } = img
    const ratio = (naturalHeight / naturalWidth) * 100
    setAspectRatio(ratio)
  }

  return (
    <div
      style={{
        width: '100%',
        ...(autoheight
          ? { paddingTop: aspectRatio ? `${aspectRatio}%` : '56.25%' }
          : { height: '100%' }),
        position: 'relative',
      }}
    >
      {image?.asset && (
        <Image
          src={src}
          alt={alt}
          title={title}
          fill={true}
          style={{ objectFit: 'cover', objectPosition }}
          placeholder="blur"
          blurDataURL={urlForImage(image).width(24).height(24).blur(10).url()}
          onLoadingComplete={handleLoadingComplete}
          {...props}
        />
      )}
    </div>
  )
}
