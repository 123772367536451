import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react'

type LocalStorageContextType = {
  bookmarkedProductIds: string[] // Change array name
  addBookmark: (productId: string) => void
  removeBookmark: (productId: string) => void
  lastViewedProductIds: string[] // Change array name
  addLastViewedProduct: (productId: string) => void
}

const LocalStorageContext = createContext<LocalStorageContextType | undefined>(
  undefined,
)

type LocalStorageProviderProps = {
  children: ReactNode
}

export const LocalStorageProvider: React.FC<LocalStorageProviderProps> = ({
  children,
}) => {
  const [bookmarkedProductIds, setBookmarkedProductIds] = useState<string[]>([]) // Rename state and variable
  const [lastViewedProductIds, setLastViewedProductIds] = useState<string[]>([]) // Rename state and variable

  useEffect(() => {
    const savedBookmarkedProductIds = JSON.parse(
      localStorage.getItem('bookmarkedProductIds') || '[]',
    )
    setBookmarkedProductIds(savedBookmarkedProductIds)

    const savedLastViewedProductIds = JSON.parse(
      localStorage.getItem('lastViewedProductIds') || '[]',
    )
    setLastViewedProductIds(savedLastViewedProductIds)
  }, [])

  const addBookmark = (productId: string) => {
    if (!bookmarkedProductIds.includes(productId)) {
      const updatedProductIds = [...bookmarkedProductIds, productId]
      localStorage.setItem(
        'bookmarkedProductIds',
        JSON.stringify(updatedProductIds),
      )
      setBookmarkedProductIds(updatedProductIds)
    }
  }

  const removeBookmark = (productId: string) => {
    const updatedProductIds = bookmarkedProductIds.filter(
      (id) => id !== productId,
    )
    localStorage.setItem(
      'bookmarkedProductIds',
      JSON.stringify(updatedProductIds),
    )
    setBookmarkedProductIds(updatedProductIds)
  }

  const addLastViewedProduct = (productId: string) => {
    const updatedProductIds = [
      productId,
      ...lastViewedProductIds.filter((id) => id !== productId),
    ].slice(0, 30)
    localStorage.setItem(
      'lastViewedProductIds',
      JSON.stringify(updatedProductIds),
    )
    setLastViewedProductIds(updatedProductIds)
  }

  return (
    <LocalStorageContext.Provider
      value={{
        bookmarkedProductIds,
        addBookmark,
        removeBookmark,
        lastViewedProductIds,
        addLastViewedProduct,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  )
}

export const useLocalStorage = () => {
  const context = useContext(LocalStorageContext)
  if (context === undefined) {
    throw new Error(
      'useLocalStorage must be used within a LocalStorageProvider',
    )
  }
  return context
}
