import { useEffect, useRef, useState } from 'react'
import { useIsMobile } from '../../lib/hooks/useIsMobile'
import { useOutsideAlerter } from '../../lib/hooks/useOutsideAlerter'
import { IdealImage } from '../shared/IdealImage'
import { useTranslation } from 'next-i18next'
import { useLocalStorage } from '../LocalStorageProvider'
import { getClient, getGetAllBookmarkedProducts } from '../../lib/sanity.client'

import { getHref } from '../../lib/helper/helper'
import { formatPrice } from '../../lib/helper/price'
import Link from '../overwrites/Link'

const Bookmarks = () => {
  const { bookmarkedProductIds, removeBookmark } = useLocalStorage()
  const [isOpen, setIsOpen] = useState(false)
  const bookmarksRef = useRef(null)
  const isMobile = useIsMobile()
  const { t, i18n } = useTranslation('common')
  const client = getClient()
  const [bookmarkedProducts, setBookmarkedProducts] = useState<
    ReducedProduct[]
  >([])
  const [isLoadingBookmarkedProducts, setIsLoadingBookmarkedProducts] = // TODO
    useState(true)
  useOutsideAlerter([bookmarksRef], () => {
    setIsOpen(false)
  })

  useEffect(() => {
    getGetAllBookmarkedProducts(bookmarkedProductIds)(
      i18n.language as AppLocale,
      client,
    ).then((fetchedBookmarkedProducts) => {
      setBookmarkedProducts(fetchedBookmarkedProducts)
      setIsLoadingBookmarkedProducts(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, bookmarkedProductIds])

  const renderBookmark = (bookmark: ReducedProduct) => {
    const { _id, image, h1, price_from } = bookmark
    return (
      <li key={_id} className="flex justify-between items-center gap-2">
        <Link
          className="flex flex-1 min-w-0 items-center gap-2 rounded-lg cursor-pointer p-1 hover:bg-gray-200"
          href={getHref(bookmark, i18n.language as AppLocale)}
          onClick={() => setIsOpen(false)}
        >
          <div className="w-12 min-w-12 h-8 relative overflow-hidden rounded-sm">
            <IdealImage
              image={image}
              alt={h1 + ' ' + t('tile') + ' - SIGHT2SIGHT'}
            />
          </div>
          <div className="flex flex-col justify-start min-w-0 mr-2">
            <span className="whitespace-nowrap font-medium text-sm truncate">
              {h1}
            </span>
            <span className="whitespace-nowrap text-xs truncate">
              {t('from')} {formatPrice({ price: price_from, currency: 'EUR' })}
            </span>
          </div>
        </Link>
        <div
          className="m-1 mr-2 cursor-pointer text-neutral hover:text-error flex items-center gap-2"
          onClick={() => removeBookmark(_id)}
        >
          <span className="text-xl far fa-trash-can" />
        </div>
      </li>
    )
  }

  useEffect(() => {
    const updateBodyClass = () => {
      const className = 'search-results-visible'
      if (isOpen) {
        document.body.classList.add(className)
      } else {
        document.body.classList.remove(className)
      }
    }

    isMobile && updateBodyClass()
    return () => document.body.classList.remove('search-results-visible')
  }, [isMobile, isOpen])

  return (
    <div ref={bookmarksRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative group hover:text-primary"
        aria-label="Set as bookmark"
      >
        <div className="flex items-center gap-2">
          {isOpen ? (
            <span className="text-xl far fa-circle-xmark" />
          ) : (
            <span className="text-xl far fa-heart" />
          )}
          <div className="text-md lg:block hidden">{t('bookmarks')}</div>
        </div>
        {!isOpen &&
          !!bookmarkedProducts.length &&
          (bookmarkedProducts.length < 10 ? (
            <span
              className={`absolute -bottom-0.5 -left-1 rounded-full far fa-circle-${bookmarkedProducts.length}`}
              style={{
                fontSize: '0.8em',
                fontWeight: 600,
                transform: 'translate(-1px, -2px)',
                backgroundColor: 'white',
              }}
            ></span>
          ) : (
            <span className="absolute -bottom-0.5 -left-1 p-0.5 bg-white text-basis border-1.5 border-basis rounded-full flex items-center justify-center group-hover:text-primary group-hover:border-primary w-auto">
              <span
                style={{
                  height: '8px',
                  minHeight: '8px',
                  minWidth: '8px',
                  fontSize: '0.7em',
                  fontWeight: 600,
                  transform: 'translate(-1px, -4px)',
                }}
              >
                {bookmarkedProducts.length}
              </span>
            </span>
          ))}
      </button>
      {isOpen && (
        <>
          <div className="absolute right-6 z-50 bg-white border text-basic rounded-lg w-96 mt-7 hidden md:block">
            <div className="mx-2 my-1">{t('your-bookmarks')}</div>
            {!isLoadingBookmarkedProducts && bookmarkedProducts.length ? (
              <ul className="overflow-y-auto max-h-60 m-1">
                {bookmarkedProducts.map(renderBookmark)}
              </ul>
            ) : (
              <div className="mx-2 my-1 text-neutral">
                {isLoadingBookmarkedProducts
                  ? t('loading-bookmarks')
                  : t('no-bookmarks')}
              </div>
            )}
          </div>
          <div
            className="absolute text-basis top-full left-0 right-0 bg-white z-50 md:hidden border-b"
            style={{
              height: window.visualViewport.height - 46 + 'px',
            }}
          >
            <div className="mx-2 mt-3 mb-1">{t('your-bookmarks')}</div>
            {!isLoadingBookmarkedProducts && bookmarkedProducts.length ? (
              <ul className="overflow-y-auto h-full m-1">
                {bookmarkedProducts.map(renderBookmark)}
                <li className="h-12 w-full"></li>
                <li className="h-12 w-full"></li>
                <li className="h-12 w-full"></li>
              </ul>
            ) : (
              <div className="mx-2 my-1 text-neutral">
                {isLoadingBookmarkedProducts
                  ? t('loading-bookmarks')
                  : t('no-bookmarks')}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Bookmarks
