import { useEffect } from "react";

export function useOutsideAlerter(
  refs: React.RefObject<HTMLDivElement>[],
  callback: () => void
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      let isOutside = true;
      for (const ref of refs) {
        if (ref.current && ref.current.contains(event.target as Node)) {
          isOutside = false;
          break;
        }
      }
      
      if (isOutside) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [refs, callback]);
}
