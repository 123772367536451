import React from 'react'

export function LoadingIndicator() {
  return (
    <div className="absolute top-full w-full left-0 h-1 right-0 mt-1 max-w-2xl overflow-hidden z-50 rounded-full">
      <div
        className="bg-primary animate-loading-bar h-4"
        aria-label="Loading"
      ></div>
    </div>
  )
}
