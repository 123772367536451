import { useTranslation } from 'next-i18next'
import AffiliatePartners from './AffiliatePartners'
import Legal from './Legal'
import PaymentPartners from './PaymentPartners'
import { Logo } from '../header/Logo'

const Footer = () => {
  const { t } = useTranslation('common')

  return (
    <footer className="bg-white w-full z-50">
      <div
        style={{ width: '95vw', height: '1px' }}
        className="border rounded-full mx-auto my-24"
      ></div>
      <div className="max-w-custom-lg mx-0 xs:mx-auto px-3 md:px-6 lg:px-8 w-full flex flex-col items-stretch justify-start m-auto gap-12">
        <AffiliatePartners />
        <Legal />
        <PaymentPartners />
        <div className="flex flex-col items-center justify-center">
          <Logo />
          <div className="text-xs mt-4 text-neutral">{t('with-love')}</div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
