import { useTranslation } from 'next-i18next'
import Link from '../overwrites/Link'
import { LOCAL_SLUGS } from '../../lib/constants'

export function Contact() {
  const { t, i18n } = useTranslation('common')
  return (
    <Link
      href={'/' + LOCAL_SLUGS[0][i18n.language]}
      className="relative"
      aria-label="Set as bookmark"
    >
      <div className="cursor-pointer hover:text-primary flex items-center gap-2">
        <span className="text-xl far fa-envelope" />
        <div className="text-md lg:block hidden">{t('contact')}</div>
      </div>
    </Link>
  )
}
