import Image from 'next/image'
import { PAYMENT_PARTNERS } from '../../lib/constants'

const PaymentPartners = () => {
  return (
    <div className="flex flex-col items-stretch">
      <div className="flex items-center flex-col md:flex-row justify-around flex-wrap gap-6 my-6">
        {PAYMENT_PARTNERS.map((partner) => (
          <div
            key={partner.name}
            className="relative flex items-center justify-center h-10"
          >
            <Image
              src={partner.logo}
              alt={partner.name}
              width={50}
              height={35}
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PaymentPartners
