import { useTranslation } from 'next-i18next'
import { useState } from 'react'

export function ContactForm() {
  const { t } = useTranslation('common')
  const [submitting, setSubmitting] = useState(false)
  const [submissionMessage, setSubmissionMessage] = useState('')
  const [emailError, setEmailError] = useState('')
  const [messageError, setMessageError] = useState('')
  const [nameError, setNameError] = useState('')

  const validateForm = (email, message, name) => {
    let isValid = true
    if (!email) {
      setEmailError(t('email_required'))
      isValid = false
    } else {
      setEmailError('')
    }
    if (!message) {
      setMessageError(t('message_required'))
      isValid = false
    } else {
      setMessageError('')
    }
    if (!name) {
      setNameError(t('name_required'))
      isValid = false
    } else {
      setNameError('')
    }
    return isValid
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (submitting) return

    const formData = new FormData(event.target)
    const email = formData.get('email')
    const message = formData.get('message')
    const name = formData.get('name')

    if (!validateForm(email, message, name)) return

    setSubmitting(true)
    setSubmissionMessage('')

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(Object.fromEntries(formData as any)),
      })

      if (response.ok) {
        setSubmissionMessage(t('email_sent_successfully'))
        event.target.reset() // Clear the form
      } else {
        setSubmissionMessage(t('failed_to_send_email'))
      }
    } catch (error) {
      setSubmissionMessage(t('error_sending_email'))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className="space-y-8 mx-3 xs:mx-0">
      <div className="space-y-2">
        <h2 className="text-3xl font-medium">{t('contact_us')}</h2>
        <p className="text-gray-500 dark:text-gray-400">{t('fill_out_form')}</p>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 xs:grid-cols-2 gap-4">
          <div>
            <label htmlFor="name" className="block">
              {t('name')}
            </label>
            <input
              id="name"
              name="name" // Add name attribute for mapping to req.body
              type="text"
              required
              placeholder={t('name')}
              className="w-full px-3 py-2 border-2 rounded-md focus:outline-none focus:border-primary"
              aria-invalid={nameError ? 'true' : 'false'}
            />
            {nameError && <p className="text-red-500">{nameError}</p>}
          </div>
          <div>
            <label htmlFor="email" className="block">
              {t('email_contact')}
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              placeholder={t('email_contact')}
              className="w-full px-3 py-2 border-2 rounded-md focus:outline-none focus:border-primary"
              aria-invalid={emailError ? 'true' : 'false'}
            />
            {emailError && <p className="text-red-500">{emailError}</p>}
          </div>
        </div>
        <div className="grid grid-cols-1 xs:grid-cols-2 gap-4">
          <div>
            <label htmlFor="city" className="block">
              {t('city')}
            </label>
            <input
              id="city"
              name="city" // Add name attribute for mapping to req.body
              type="text"
              placeholder={t('city')}
              className="w-full px-3 py-2 border-2 rounded-md focus:outline-none focus:border-primary"
            />
          </div>
          <div>
            <label htmlFor="postal-code" className="block">
              {t('postal_code')}
            </label>
            <input
              id="postal-code"
              name="postalCode" // Add name attribute for mapping to req.body
              type="text"
              placeholder={t('postal_code')}
              className="w-full px-3 py-2 border-2 rounded-md focus:outline-none focus:border-primary"
            />
          </div>
        </div>
        <div>
          <label htmlFor="message" className="block">
            {t('message')}
          </label>
          <textarea
            id="message"
            name="message"
            required
            placeholder={t('message')}
            className="w-full px-3 py-2 border-2 rounded-md focus:outline-none focus:border-primary min-h-[130px]"
            aria-invalid={messageError ? 'true' : 'false'}
          ></textarea>
          {messageError && <p className="text-red-500">{messageError}</p>}
        </div>
        <button
          disabled={submitting}
          className="py-2 px-3 rounded-lg bg-primary text-white flex items-center justify-center gap-2 hover:brightness-90 disabled:bg-gray-400"
          type="submit"
          aria-label={t('send_message')}
        >
          <span className="fa-solid fa-envelope" />
          {submitting ? t('sending') : t('send_message')}
        </button>
        {submissionMessage && (
          <p
            className={
              submissionMessage === t('email_sent_successfully')
                ? 'text-green-500'
                : 'text-red-500'
            }
          >
            {submissionMessage}
          </p>
        )}
      </form>
    </div>
  )
}
