import React, { Suspense, lazy } from 'react'
import 'tailwindcss/tailwind.css'
import '../public/assets/custom.css'
import '../public/fontawesome/css/regular.min.css'
import '../public/fontawesome/css/solid.min.css'
import '../public/fontawesome/css/duotone.min.css'
import '../public/fontawesome/css/fontawesome.min.css'
import { AppProps } from 'next/app'
import { Header } from '../components/header/Header'
import { appWithTranslation } from 'next-i18next'
import { Breadcrumbs } from '../components/shared/Breadcrumbs'
import { AppWidthContainer } from '../components/AppWidthContainer'
import { LocalStorageProvider } from '../components/LocalStorageProvider'
import Footer from '../components/footer/Footer'
// import { useRouter } from 'next/router'

export interface SharedPageProps {
  draftMode: boolean
  token: string
}

const PreviewProvider = lazy(() => import('../components/PreviewProvider'))
const VisualEditing = lazy(() => import('../components/VisualEditing'))

function App({ Component, pageProps }: AppProps<SharedPageProps>) {
  const { draftMode, token } = pageProps
  // const router = useRouter()

  // useEffect(() => {
  //   const preferredLocale = localStorage.getItem('next-i18next-locale')
  //   if (
  //     preferredLocale &&
  //     preferredLocale !== router.locale &&
  //     router.locales.includes(preferredLocale)
  //   ) {
  //     const shouldRedirect = !router.asPath.startsWith(
  //       '/' + preferredLocale + '/',
  //     )
  //     if (shouldRedirect) {
  //       router.push(router.pathname, router.asPath, { locale: preferredLocale })
  //     }
  //   }
  // }, [router])

  return (
    <LocalStorageProvider>
      <Header />
      <AppWidthContainer>
        <Breadcrumbs />
        {draftMode ? (
          <PreviewProvider token={token}>
            <Component {...pageProps} />
          </PreviewProvider>
        ) : (
          <Component {...pageProps} />
        )}
        {draftMode && (
          <Suspense fallback={<div>Loading...</div>}>
            <VisualEditing />
          </Suspense>
        )}
      </AppWidthContainer>
      <Footer />
      <div className="h-24" />
    </LocalStorageProvider>
  )
}

export default appWithTranslation(App)
