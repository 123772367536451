import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import { useIsMobile } from '../../lib/hooks/useIsMobile'

interface Props {
  searchTerm: string
  onSearchTermChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearchKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onClearSearch: () => void
  onClick: (ref: any) => void
  searchFocused: boolean
}

export const SearchInput: React.FC<Props> = ({
  searchTerm,
  onSearchTermChange,
  onSearchKeyDown,
  onClearSearch,
  onClick,
  searchFocused,
}) => {
  const { t } = useTranslation('common')
  const isMobile = useIsMobile()
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClearSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault() // Prevents the default action of the event
    event.stopPropagation() // Stops the event from propagating up the DOM tree

    onClearSearch()
    inputRef.current?.focus()
  }

  // Use useEffect to listen for changes in searchFocused
  useEffect(() => {
    if (searchFocused) {
      inputRef.current?.focus()
    }
  }, [searchFocused]) // Only re-run the effect if searchFocused changes

  return (
    <div className="mx-3 xs:mx-1 bg-white flex flex-grow items-center justify-start overflow-hidden max-w-2xl border-2 rounded-3xl">
      <span className="text-xl ml-3 far fa-magnifying-glass" />
      <input
        type="text"
        id="search-sight-2-sight"
        name="search-sight-2-sight"
        placeholder={t('search-placeholder')}
        value={searchTerm}
        onChange={onSearchTermChange}
        onKeyDown={onSearchKeyDown}
        onClick={onClick}
        className="flex-1 min-w-0 p-2 focus:outline-none text-basis placeholder-basis leading-tight"
        aria-label={t('search')}
        ref={inputRef}
      />
      {(searchTerm.length > 0 || (isMobile && searchFocused)) && (
        <button
          className="p-1 xs:mr-3 mr-2 w-8 xs:w-6 h-8 xs:h-6 hover:brightness-90 focus:outline-none transition-colors flex items-center justify-center z-50"
          onMouseDown={handleClearSearch}
          type="button"
          aria-label="Clear search input"
        >
          <span className="text-xl far fa-circle-xmark" />
        </button>
      )}
    </div>
  )
}
