import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { AFFILIATE_PARTNERS } from '../../lib/constants'

const AffiliatePartners = () => {
  const { t } = useTranslation('common')

  return (
    <div className="flex flex-col items-stretch">
      <div
        className="text-xl font-medium mb-5 uppercase text-center"
        style={{ color: '#4d4d4d' }}
      >
        {t('our-partners')}
      </div>
      <div className="flex items-center flex-col md:flex-row justify-around flex-wrap gap-6 my-6">
        {AFFILIATE_PARTNERS.map((partner) => (
          <div key={partner.name} className="flex items-center justify-center">
            <Image
              src={partner.logo}
              alt={partner.name}
              width={partner.width || 100}
              height={partner.height || 30}
              className="w-auto h-auto grayscale"
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default AffiliatePartners
