import {
  CITY_DESTINATIONS,
  ICONS_FOR_CATEGORIES,
  LANGUAGES,
  SEARCH_RESULTS_TYPE_ORDER,
  STATE_NAMES,
} from '../constants'

export function replaceWildcards(str: string, passedWildcards?: Wildcards) {
  if (!str) return str
  const wildcards = {
    year: new Date().getFullYear(),
    año: new Date().getFullYear(),
    ano: new Date().getFullYear(),
    ...(passedWildcards || {}),
  }
  if (wildcards.number_activities) {
    wildcards['número_actividades'] = wildcards.number_activities
    wildcards['numero_actividades'] = wildcards.number_activities
  }
  Object.keys(wildcards).forEach((wildcard) => {
    const value = wildcards[wildcard]
    str = str.replace
      ? str.replace(new RegExp(`\\[${wildcard}\\]`, 'g'), value || '')
      : str
  })

  return str
}

export function getAlternates(item: any) {
  return LANGUAGES.map((locale: AppLocale) => ({
    hrefLang: locale,
    href: `${process.env.NEXT_PUBLIC_URL}${locale === 'en' ? '' : '/' + locale}${getHref(item, locale)}`,
  }))
}

const getCitySlug = (localizedSlug: string, locale: AppLocale): string => {
  for (const [citySlug, names] of Object.entries(STATE_NAMES)) {
    if (names[locale] === localizedSlug) {
      return CITY_DESTINATIONS[citySlug][locale]
    }
  }
  return localizedSlug
}

export const getHref = (item: any, locale: AppLocale): string => {
  const { _type, slug, location } = item
  const localizedSlug = slug[locale]?.current
  const { slug: locationSlug, _type: _typeLocation } = location || {}
  const locationSlugLocalized = (locationSlug || {})[locale]?.current

  switch (_type) {
    case 'country':
      return `/l/${localizedSlug}`
    case 'region':
      return `/r/${localizedSlug}`
    case 'state':
      if (JSON.stringify(STATE_NAMES).includes(localizedSlug)) {
        return `/c/${getCitySlug(localizedSlug, locale)}`
      }
      return `/s/${localizedSlug}`
    case 'city':
      return `/c/${localizedSlug}`
    case 'category':
      return `/c/${locationSlugLocalized}/c/${localizedSlug}`
    case 'product':
      if (!item.has_product_page && item.product_link) {
        return item.product_link
      }
      return `/c/${locationSlugLocalized}/a/${localizedSlug}`
    default:
      return '/'
  }
}
export function determineEnginePartner(
  affiliateLink?: string,
  widgetCode?: string,
) {
  if (affiliateLink && affiliateLink.includes('getyourguide.')) {
    return 'getyourguide'
  }

  if (widgetCode) {
    if (widgetCode.includes('data-gyg-')) {
      return 'getyourguide'
    }
    if (
      widgetCode.includes('booking-widget') &&
      widgetCode.includes('regiondo.net')
    ) {
      return 'abicht'
    }
  }

  return null
  // { title: 'Abicht', value: 'abicht' },
  // { title: 'GetYourGuide', value: 'getyourguide' },
  // { title: 'Viator', value: 'viator' },
  // { title: 'Klook', value: 'klook' },
  // { title: 'Musement', value: 'musement' },
  // { title: 'Tiqets', value: 'tiqets' },
  // { title: 'Ticketbar', value: 'ticketbar' },
}

const groupByTypeAndSort = (results) => {
  return results.reduce((acc, item) => {
    const type = item._type
    if (!acc[type]) {
      acc[type] = []
    }
    acc[type].push(item)
    acc[type].sort((a, b) => {
      if ((a.priority || 0) === (b?.priority || 0)) {
        return (
          (b?.reviews?.numberOfReviews || 0) -
          (a?.reviews?.numberOfReviews || 0)
        )
      }
      return (b?.priority || 0) - (a?.priority || 0)
    })
    return acc
  }, {})
}

export const limitAndSortResults = (results) => {
  const groupedAndSortedResults = groupByTypeAndSort(results)
  const limitedResults = []

  SEARCH_RESULTS_TYPE_ORDER.forEach((type) => {
    if (groupedAndSortedResults[type]) {
      limitedResults.push(...groupedAndSortedResults[type].slice(0, 5))
    }
  })

  return limitedResults.sort(
    (a, b) =>
      SEARCH_RESULTS_TYPE_ORDER.indexOf(a._type) -
      SEARCH_RESULTS_TYPE_ORDER.indexOf(b._type),
  )
}

export const getTileHeight = (type, isSlider) => {
  return {
    category: 'h-64',
    location: 'h-44',
    product: isSlider ? 'h-48' : 'h-48',
  }[type]
}

export const getTileWidth = (type, isSlider) => {
  return {
    category: 'w-40',
    location: isSlider ? 'w-64' : '',
    product: isSlider ? 'w-64' : '',
  }[type]
}

export const getTileMinWidth = (type, isSlider) => {
  return {
    category: '170px',
    location: '200px',
    product: isSlider ? '200px' : '14rem',
  }[type]
}

export function ensurePartnerIdAndCampaign(url: string) {
  let modifiedUrl = new URL(url)
  let hasModified = false
  const params = {
    partner_id: 'W60CGI8',
    cmp: 's2s',
  }

  // Iterate through each param to check if it exists, and add it if it doesn't
  Object.entries(params).forEach(([key, value]) => {
    if (!modifiedUrl.searchParams.has(key)) {
      modifiedUrl.searchParams.set(key, value)
      hasModified = true
    } else {
      // If the parameter exists but the value is different, update it
      if (modifiedUrl.searchParams.get(key) !== value) {
        modifiedUrl.searchParams.set(key, value)
        hasModified = true
      }
    }
  })

  return hasModified ? modifiedUrl.toString() : url
}

export const getIconByNameDe = (nameDe: string): string => {
  const iconNames = Object.keys(ICONS_FOR_CATEGORIES)
  const closestIconName = iconNames.reduce((prev, curr) => {
    return levenshteinDistance(curr, nameDe) < levenshteinDistance(prev, nameDe)
      ? curr
      : prev
  })
  return ICONS_FOR_CATEGORIES[closestIconName]
}

export const levenshteinDistance = (a: string, b: string): number => {
  const matrix = []
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i]
  }
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j
  }
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1]
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j] + 1,
        )
      }
    }
  }
  return matrix[b.length][a.length]
}
