export function getSearchQuery(locale: AppLocale, query: string) {
  return `
  *[
    _type in ["country", "city", "category", "product", "region", "state"] && (
      name.${locale} match "${query}*" || 
      h1.${locale} match "${query}*" || 
      slug.${locale}.current match "${query}*" || 
      name_description.${locale} match "${query}*" ||
      (
        _type == "product" && (
          location[0]->name.${locale} match "${query}*" ||
          location[0]->slug.${locale}.current match "${query}*" ||
          location[0]->name_description.${locale} match "${query}*" ||
          location[0]->country->name.${locale} match "${query}*" ||
          location[0]->country->slug.${locale}.current match "${query}*" ||
          location[0]->country->name_description.${locale} match "${query}*"
        )
      ) ||
      search_terms match "${query}*"
    )
  ]{
    _id,
    _type,
    "h1": h1.${locale},
    slug,
    has_product_page,
    product_link,
    image,
    "name": name.${locale},
    coverImage,
    "city": city->{
      _id,
      "name": name.${locale},
      slug,
    },
    "location1": location[0]->{
      _id,
      "name": name.${locale},
      slug,
    },
    "location2": location->{
      _id,
      "name": name.${locale},
      slug,
    },
    price_from,
    reviews,
    priority,
  }
`
}
