import Image from 'next/image'
import logo from '../../public/assets/logo.png'
import Link from '../overwrites/Link'

export function Logo() {
  return (
    <Link href="/" className="">
      <Image src={logo} width={280} height={80} alt="Logo SIGHT2SIGHT" />
    </Link>
  )
}
