import { useEffect, useRef, useState } from 'react'
import { useIsMobile } from '../../lib/hooks/useIsMobile'
import { useOutsideAlerter } from '../../lib/hooks/useOutsideAlerter'
import { useTranslation } from 'next-i18next'

const Cart = () => {
  const [isOpen, setIsOpen] = useState(false)
  const cartRef = useRef(null)
  const isMobile = useIsMobile()
  const { t } = useTranslation('common')
  useOutsideAlerter([cartRef], () => {
    setIsOpen(false)
  })

  useEffect(() => {
    const updateBodyClass = () => {
      const className = 'search-results-visible'
      if (isOpen) {
        document.body.classList.add(className)
      } else {
        document.body.classList.remove(className)
      }
    }

    isMobile && updateBodyClass()
    return () => document.body.classList.remove('search-results-visible')
  }, [isMobile, isOpen])

  return (
    <div ref={cartRef} className="hidden md:block">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative group hover:text-primary w-4"
        aria-label="Open cart"
      >
        <div className="flex items-center gap-2">
          {isOpen ? (
            <span className="text-xl far fa-circle-xmark" />
          ) : (
            <span className="text-xl far fa-shopping-bag" />
          )}
        </div>
      </button>
      {isOpen && (
        <div className="absolute right-6 z-50 bg-white border text-basic rounded-lg w-96 mt-7 hidden md:block">
          <div className="mx-2 my-1">{t('cart')}</div>
          <div className="mx-2 my-1 text-neutral">{t('cart-empty')}</div>
        </div>
      )}
      {isOpen && (
        <div
          className="absolute text-basis top-full left-0 right-0 bg-white z-50 md:hidden border-b"
          style={{
            height: window.visualViewport.height - 46 + 'px',
          }}
        >
          <div className="mx-2 mt-3 mb-1">{t('cart')}</div>
          <div className="mx-2 my-1 text-neutral">{t('cart-empty')}</div>
        </div>
      )}
    </div>
  )
}

export default Cart
