import React, { Fragment } from 'react'
import { usePathname } from 'next/navigation'

import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { GYGAdNotice } from './GYGAdNotice'
import Link from '../overwrites/Link'

export function Breadcrumbs() {
  const paths = usePathname()
  const { t } = useTranslation('common')
  const { pathname } = useRouter()
  const pathNames = paths.split('/').filter((path) => path)
  if (pathNames.length < 1 || pathname === '/') {
    return <div className="xs:h-3 md:h-6 lg:h-6"></div>
  }
  const separator = (
    <li>
      <span className="mx-1">/</span>
    </li>
  )

  const pathsWithoutGYGAdNotice = [
    '/kontakt',
    '/impressum',
    '/datenschutz',
    '/agb',
  ]

  return (
    <div className="my-3 flex justify-between items-baseline xs:items-center px-3 xs:px-0">
      <div className="flex-grow w-full">
        <ul className="flex items-center flex-wrap text-xs xs:text-sm font-regular text-neutral">
          <li className="">
            <Link href={'/'}>{t('breadcrumb-start')}</Link>
          </li>
          {separator}
          {pathNames.map((link, index) => {
            let href = `/${pathNames.slice(0, index + 1).join('/')}`
            if (['c', 'd', 'l', 'r', 's', 'a', 'p'].includes(link)) {
              return null
            }
            const isCurrentPage = href === paths

            let beautifiedLink = link
              .replace(' and ', ' & ')
              .replace(' und ', ' & ')
              .replace(/-/g, ' ')

            // if (i18n.language === 'de') {
            //   beautifiedLink = beautifiedLink
            //     .replace(/ae/g, 'ä')
            //     .replace(/oe/g, 'ö')
            //     .replace(/ue/g, 'ü')
            // }

            return (
              <Fragment key={index}>
                <li
                  className={`capitalize ${isCurrentPage ? 'text-primary' : ''}`}
                >
                  <Link href={href}>{beautifiedLink}</Link>
                </li>
                {pathNames.length !== index + 1 && separator}
              </Fragment>
            )
          })}
        </ul>
      </div>
      {!pathsWithoutGYGAdNotice.includes(pathname) && <GYGAdNotice />}
    </div>
  )
}
