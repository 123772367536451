type Props = {
  children: React.ReactNode
}

export function AppWidthContainer({ children }: Props) {
  return (
    <div className="max-w-custom-lg mx-0 xs:mx-auto xs:px-3 md:px-6 lg:px-8">
      {children}
    </div>
  )
}
