import React from 'react'
import DefaultLink from 'next/link'
import { useTranslation } from 'next-i18next'

interface CustomLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  passHref?: boolean // Make it optional as it's not always needed
}

export const transformHref = (originalHref: string, locale: AppLocale) => {
  if (originalHref.includes('getyourguide.')) {
    const url = new URL(originalHref)
    const domainMap: { [key in AppLocale]: string } = {
      en: 'com',
      de: 'de',
      es: 'es',
      nl: 'nl',
      fr: 'fr',
      it: 'it',
    } // Extend this object based on your supported locales
    const localeDomain = domainMap[locale] || 'com'

    // Replace the domain
    url.hostname = url.hostname.replace(/\.(com|de)$/, `.${localeDomain}`) // Add all possible TLDs in the regex

    // Clear existing params and add required ones
    url.searchParams.delete('partner_id')
    url.searchParams.delete('cmp')
    url.searchParams.set('partner_id', 'W60CGI8')
    url.searchParams.set('cmp', 's2s')

    return url.toString()
  }
  return originalHref
}

const Link: React.FC<CustomLinkProps> = ({ href, children, ...rest }) => {
  const { i18n } = useTranslation()
  const modifiedHref = transformHref(href, i18n.language as AppLocale)

  return (
    <DefaultLink href={modifiedHref} {...rest}>
      {children}
    </DefaultLink>
  )
}

export default Link
