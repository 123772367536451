import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { getClient } from '../../lib/sanity.client'
import { IdealImage } from '../shared/IdealImage'
import { useIsMobile } from '../../lib/hooks/useIsMobile'
import { LANGUAGES, LOCAL_SLUGS } from '../../lib/constants'

const LanguageSwitcher = () => {
  const client = getClient()
  const router = useRouter()
  const isMobile = useIsMobile()
  const languageSwitcher = useRef(null)
  const [localesAsFlags, setLocalesAsFlags] = useState([])
  const [menuOpen, setMenuOpen] = useState(false)
  const { t, i18n } = useTranslation('common')

  useEffect(() => {
    const desiredIds = [
      'ceb27340-f2ee-437c-a180-7c4a958abdca', // de
      '0bac633c-d8ff-4641-b0bc-50f3d1240982', // en
      'ce3fe826-805c-470f-9b2c-ea8130fe13ef', // es
      '90aae465-3cb2-4f12-9295-0b3bb02975ac', // nl
      '41e4fe70-4959-4068-916d-08efb5d7b4b8', // fr
      '3df92c31-e388-4ae2-aeb3-8cf7f2bf1ff5', // it
    ]
    const idsForQuery = `_id in [${desiredIds.map((id) => `"${id}"`).join(',')}]`
    client
      .fetch(
        `*[_type == "locale_language" && ${idsForQuery}]{
          code,
          image,
          "text": text.${i18n.language}
        }`,
      )
      .then((locales) => {
        setLocalesAsFlags(locales)
      })

    // Click outside listener
    const handleClickOutside = (event) => {
      if (
        languageSwitcher.current &&
        !languageSwitcher.current.contains(event.target)
      ) {
        setMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  async function fetchSlugFromSanity(docType, currentSlug, locale) {
    const docTypesToQuery =
      docType === 'any' ? '"imprint", "privacy", "terms"' : `"${docType}"`
    const query = `*[ _type in [${docTypesToQuery}] && slug.${i18n.language}.current == $currentSlug]{ "newSlug": slug.${locale}.current }`
    const params = { currentSlug }
    const result = await client.fetch(query, params)

    return result[0]?.newSlug || '/'
  }

  async function handleLanguageChange(lng) {
    const { pathname, query } = router
    const fetchSlugsPromises = Object.keys(query).map(async (key) => {
      const currentSlug = query[key]
      let newSlug
      if (LOCAL_SLUGS.find((slug) => slug[i18n.language] === currentSlug)) {
        newSlug = LOCAL_SLUGS.find(
          (slug) => slug[i18n.language] === currentSlug,
        )[lng]
      } else {
        newSlug = await fetchSlugFromSanity(key, currentSlug, lng)
      }
      return { key, newSlug }
    })

    const slugsResults = await Promise.all(fetchSlugsPromises)
    let newPathname = pathname
    slugsResults.forEach(({ key, newSlug }) => {
      newPathname = newPathname.replace(`[${key}]`, newSlug)
    })

    router.push(newPathname, newPathname, { locale: lng })
    localStorage.setItem('next-i18next-locale', lng)
    setMenuOpen(false) // Close menu after selection
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const languages = LANGUAGES.map((lng) => {
    const { image, text } = localesAsFlags.find((l) => l.code === lng) || {}
    return (
      <button
        key={lng}
        onClick={() => handleLanguageChange(lng)}
        className="flex flex-1 min-w-0 m-1 items-center gap-2 rounded-md text-xl xs:text-sm cursor-pointer py-1 px-1 hover:bg-gray-200"
        aria-label={t(`switch_to_${lng}`)}
      >
        <span className="w-6 h-4 rounded-xs overflow-hidden">
          <IdealImage image={image} alt={lng} />
        </span>
        {text}
      </button>
    )
  })

  const flagToDisplay = localesAsFlags.find(
    (l) => l.code === i18n.language,
  )?.image

  return (
    <div className="relative group language-switcher" ref={languageSwitcher}>
      <div
        className="flex items-center gap-2"
        onClick={toggleMenu}
        role="button"
        tabIndex={0}
      >
        <span className="w-6 h-6 flex items-center">
          <span className="w-full h-4 rounded-sm overflow-hidden cursor-pointer">
            {flagToDisplay && (
              <IdealImage image={flagToDisplay} alt={i18n.language} />
            )}
          </span>
        </span>
      </div>
      {(!isMobile || menuOpen) && (
        <div
          className={`${isMobile ? '' : 'group-hover:flex flex-col items-stretch hidden'} z-50 absolute h-auto right-0 z-50 md:mt-3 mt-2 bg-white border text-basic rounded-lg shadow-lg`}
          role="menu"
          aria-orientation="vertical"
        >
          {languages}
        </div>
      )}
    </div>
  )
}

export default LanguageSwitcher
