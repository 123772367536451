import { groq } from 'next-sanity'

export const getAllRegionsInCountryQuery = (
  locale: AppLocale,
  countryId: string,
  offset?: number,
) => groq`
  *[_type == "region" && "${countryId}" in country[]->_id && defined(slug.${locale}.current)]
  | order(coalesce(number_activities.${locale}, 0) desc)
  {
    ${getReducedRegionFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

export const getAllRegionsInStateQuery = (
  locale: AppLocale,
  stateId: string,
  offset?: number,
) => groq`
  *[_type == "region" && state->_id == "${stateId}" && defined(slug.${locale}.current)]
  | order(coalesce(number_activities.${locale}, 0) desc)
  {
    ${getReducedRegionFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

export const getAllRegionsQuery = (locale: AppLocale, offset?: number) => groq`
  *[_type == "region" && defined(slug.${locale}.current)]
  | order(coalesce(number_activities.${locale}, 0) desc)
  {
    ${getReducedRegionFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

const getReducedRegionFields = (locale: AppLocale) => ` 
  _id,
  _type,
  _updatedAt,
  "name": name.${locale},
  slug,
  coverImage,
  priority,
  number_locations,
  "number_activities": number_activities.${locale},
`

export const getRegionBySlugQuery = (
  locale: AppLocale,
  regionSlug: string,
) => groq`
  *[_type == "region" && slug.${locale}.current == "${regionSlug}"][0] {
    _id,
    _type,
    "name": name.${locale},
    "nameDescription": name_description.${locale},
    "h1": h1.${locale},
    slug,
    coverImage,
    "highlights": highlights.${locale},
    "description": description.${locale},
    "description2": description2.${locale},
    "description3": description3.${locale},
    "faq": faq.${locale},
    reviews,
    "meta_title": meta_title.${locale},
    "meta_description": meta_description.${locale},
    "locations": locations[]->{
      _id,
      "name": name.${locale},
      slug,
    },
    number_locations,
    "number_activities": number_activities.${locale},
  }
`
