import React, { useEffect, useState } from 'react'
import { Logo } from './Logo'
import Bookmarks from './Bookmarks'
import { Search } from '../search/Search'
import { useIsMobile } from '../../lib/hooks/useIsMobile'
import { useRouter } from 'next/router'
import { Contact } from './Contact'
import Cart from './Cart'
import LanguageSwitcher from './LanguageSwitcher'

export function Header() {
  const isMobile = useIsMobile(768)
  const [searchFocused, setSearchFocused] = useState<boolean>(false)
  const { pathname } = useRouter()
  const isNotRoot = pathname !== '/'
  const showSearchAsIcon = isMobile && isNotRoot

  useEffect(() => {
    setSearchFocused(false)
  }, [pathname])

  const renderSearchAsIcon = () => {
    return (
      <button
        onClick={() => {
          setSearchFocused(true)
        }}
        aria-label="Search SIGHT2SIGHT"
      >
        <span className="text-xl far fa-magnifying-glass" />
      </button>
    )
  }

  return (
    <header className="bg-white w-full border-b-2 h-14 xs:h-20 z-50">
      <div className="relative grid grid-cols-2 md:grid-cols-3 max-w-custom-lg mx-0 md:mx-auto px-3 md:px-6 lg:px-8 w-full h-full items-center gap-6 justify-end-center m-auto">
        <div className="justify-self-start">
          <Logo />
        </div>
        {(!showSearchAsIcon || searchFocused) && (
          <>
            <Search
              searchFocused={searchFocused}
              setSearchFocused={setSearchFocused}
            />
          </>
        )}
        <div className="justify-self-end flex items-center xs:gap-5 gap-4 mt-2 xs:self-center">
          {showSearchAsIcon && !searchFocused && renderSearchAsIcon()}
          <Bookmarks />
          <Contact />
          <Cart />
          <LanguageSwitcher />
        </div>
      </div>
    </header>
  )
}
